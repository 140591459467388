// This file is compiled inside Decidim core pack. Code can be added here and will be executed
// as part of that pack

// Load images
require.context("../../images", true)

document.addEventListener("DOMContentLoaded", function() {
  if (document.getElementById("session_user_email") && document.getElementById("session_user_password")) {
      document.getElementById("session_user_email").value = "admin@example.org";
      document.getElementById("session_user_password").value = "decidim123456789";
  }
});
